<template>
  <div class="comp-Dynamic">
    <div class="openbg">
      <div class="prize_box">
        <div class="Dynamic_out">
          <div class="container" :style="LeftSty" :class="TranSitionSty">
            <div class="contItem" :style="'width:' + WidTh + 'rem'" v-for="(item, index) in DetailArr" :key="index">
              <div class="cont_pic" :class="!item?.cover || item.name ? 'activeimg' : 'noactive'">
                <img :src="item?.cover || item" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="left-float"></div>
    <div class="right-float"></div>
    <div class="line-float"></div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "Dynamic",
  props: {
    DetailList: {
      //箱内物品
      type: Array,
    },
    Awardlist: {
      //奖品列表
      type: Array,
    },
    Dynamic_checked: {
      //动画播放
      type: String,
    },
    Dynamic_num: {
      //奖品数量
      type: Number,
    },
    NumIdx: {
      type: [Number, String],
      default: 5,
    },
  },

  data() {
    return {
      LeftSize: "0", //偏移距离
      TranSition: "active", //过度曲线
      DetailArr: [], //箱内物品(重置后)
      also: 0, //假数据(判断是否继续处理)
      WidTh:  0.63,  //0.87
      Timer: null,
    };
  },
  beforeDestroy() {
    this.mp3Start && this.mp3Start.pause();
    this.mp3End && this.mp3End.pause();
    clearTimeout(this.Timer);
  },
  methods: {
    ...mapMutations(["ChangeAudioStatus"]),
    //拼接并打乱数组
    Arraysplicing() {
      let A_Arr = [];
      const odd = Math.round(this.NumIdx / 10);
      let Item = null;
      let coverItem = null;
      this.DetailList.forEach((item) => {
        if (!item.cover) {
          Item = item;
        } else {
          coverItem = item;
        }
      });
      let array = new Array(10).fill(coverItem);
      for (let i = 0; i < odd; i++) {
        array[i] = Item;
      }
      for (let i = 0; i < 20; i++) {
        A_Arr = A_Arr.concat(array);
      }

      //打乱
      function randSort(A_Arr) {
        for (let i = 0, len = A_Arr.length; i < len; i++) {
          let rand = parseInt(Math.random() * len);
          let temp = A_Arr[rand];
          A_Arr[rand] = A_Arr[i];
          A_Arr[i] = temp;
        }
        return A_Arr;
      }

      this.DetailArr = randSort(A_Arr);
      console.log(this.DetailArr, "DetailArr");

    },

    //处理中奖
    Determine() {
      if (this.also < this.Dynamic_num) {
        this.DetailArr[30] = this.Awardlist[this.also]; //奖品挪移
        this.TranSition = "active"; //增加过度动画
        // -0.5-1.3之间的随机数
        // let randomNum = Math.random() * 1.8;
        let randomNum = (Math.random() * this.WidTh * 0.8) + this.WidTh * 0.1;
        this.LeftSize = -(this.WidTh * 27 + randomNum); //偏移值
        if (this.soundStatus) {
          this.mp3Start && this.mp3Start.play(); //播放声音
        }
        this.also += 1; //自加1
        this.Timer = setTimeout(() => {
          if (this.soundStatus) {
            this.mp3End && this.mp3End.play();
            setTimeout(() => {
              this.ChangeAudioStatus(true);
            }, 1000);
          }
          this.IsRepeat();
        }, 6900);
      } else {
        //子传父(动画结束)
        this.$emit("setAnimation", true);
        this.LeftSize = "0"; //偏移值复原
      }
    },

    //重复
    IsRepeat() {
      this.$emit("setEquipment", this.also);
      let alsoArr = [];
      // alsoArr.unshift(this.DetailArr[31], this.DetailArr[32], this.DetailArr[33]); //上次偏移到最后的三件物品
      setTimeout(() => {
        this.Arraysplicing(); //重新调用打乱
      }, 500);
      // this.DetailArr = alsoArr.concat(this.DetailArr); //上次最后的三件物品插入新打乱的数组
      this.TranSition = ""; //清掉过度动画
      // this.LeftSize = "0"; //偏移值复原
      setTimeout(() => {
        this.Determine();
      }, 100);
    },
  },

  computed: {
    ...mapState(["soundStatus", "mp3Start", "mp3End"]),
    //偏移样式
    LeftSty() {
      return "transform: translateX(" + this.LeftSize + "rem)";
    },
    //过渡样式
    TranSitionSty() {
      return this.TranSition;
    },
  },

  watch: {
    DetailList: {
      immediate: true,
      deep: true,
      handler() {
        this.Arraysplicing();
      },
    },
    NumIdx: {
      immediate: true,
      deep: true,
      handler() {
        this.Arraysplicing();
      },
    },

    //为'1'处理中奖数据并播放动画
    Dynamic_checked(str) {
      this.also = 0;
      if (str == "1") {
        this.Determine();
      }
    },
  },
};
</script>

<style lang="scss">
.comp-Dynamic {
  width: 100%;
  height: 0.64rem;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 397;
  .left-float {
    width: 0.42rem;
    height: 0.63rem;
    background: linear-gradient(90deg, #000000 28%, rgba(1, 3, 11, 0) 100%);
    position: absolute;
    z-index: 100;
    left: -0.18rem;
    top: 0rem;
  }
  .right-float {
    width: 0.42rem;
    height: 0.63rem;
    background: linear-gradient(270deg, #000000 28%, rgba(1, 3, 11, 0) 100%);
    position: absolute;
    z-index: 100;
    right: -0.18rem;
    top: 0rem;
  }
  .line-float {
    width: 120%;
    height: 0.98rem;
    //  width: 100%;
    // height: 1.21rem;
    background: url(../../assets/newImages/open-bg1-new.png) no-repeat
      center;
    background-size: 100% 100%;
    position: absolute;
    z-index: 110;
    left: 0rem;
    // top: -0.28rem;
    top: -26%;
  }
  .openbg {
    width: 100%;
    height: 100%;
    .prize_box {
      position: relative;
      background: #000;
      height: 100%;
      .Dynamic_out {
        overflow-x: hidden;
        height: 100%;

        .container {
          // overflow: hidden;
          height: 100%;
          display: flex;

          &.active {
            transition: 6s cubic-bezier(0, 0, 0.28, 1);
          }

          .contItem {
            flex-shrink: 0;
            background: url(../../assets/newImages/open-blind-award-bg-lv1.png) no-repeat
              center;
            background-size: 100% 100%;
            .cont_pic {
              height: 100%;
              background-position: center;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                vertical-align: middle;
                max-width: 100%;
                max-height: 100%;
                padding: 0 0.03rem;
              }
            }

            .activeimg {
              background: url(../../assets/newImages/open-blind-award-bg-lv2.png)
                no-repeat center;
              background-size: 100% 100%;
            }

            .noactive {
              background: url(../../assets/newImages/open-blind-award-bg-lv4.png)
                no-repeat center;
              background-size: 100% 100%;
            }
          }
        }
      }
    }
  }
}
</style>
