<template>
  <div id="Show">
    <van-overlay @mousewheel.prevent.native="() => { }" :show="true">
      <div class="wrapper">
        <div class="Show_box1">
          <LuckyOpen :imgList="Awardlist" @cancelFun="Postcash" @confirmFun="setShow" />
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Cash, PersonalInfo } from "@/network/api.js";
import { mapMutations } from "vuex";
import LuckyOpen from "@/components/singleBox/LuckyOpen.vue";
export default {
  components: {
    LuckyOpen,
  },
  name: "Show",
  props: {
    Awardlist: {
      //奖品列表
      type: Array,
    },
    WinningShow: {
      //控制声音
      type: Boolean,
    },
  },
  data() {
    return {
      TotalPrice: 0, //回收总价格
      enterid: null, //当前物品id
    };
  },
  created() {
    this.getid();
  },
  methods: {
    getid() {
      this.enterid = this.$route.query.id;
    },
    //默认全部选中
    Select_All() {
      for (let i = 0; i < this.Awardlist.length; i++) {
        let key = "Select";
        let value = true;
        this.Awardlist[i][key] = value;
        setTimeout(() => {
          this.$refs.Select[i].style.border = "1px solid #E9B10E";
        }, 10);
      }
      this.TotalPriceCal();
    },

    //选中
    SelectItem(item, index) {
      if (item.Select) {
        delete item.Select;
        this.$refs.Select[index].style.border = "1px solid transparent";
      } else {
        let key = "Select";
        let value = true;
        item[key] = value;
        this.$refs.Select[index].style.border = "1px solid #E9B10E";
      }
      this.TotalPriceCal();
    },

    // 计算回收总价
    TotalPriceCal() {
      this.TotalPrice = 0;
      let A_Arr = this.Awardlist.filter((v) => {
        return v.Select == true;
      });

      for (let k = 0; k < A_Arr.length; k++) {
        this.TotalPrice += Number(A_Arr[k].bean);
      }
    },

    //兑换金币(回收)
    Postcash(chooseList) {
      if (!chooseList || !chooseList.length) {
        this.$message({
          message: "请选择要回收的物品",
          type: "warning",
        });
        return false;
      }
      Cash(chooseList).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "回收成功",
            type: "success",
          });
          this.GetPersonalInfo();
          this.setShow();
        } else {
          this.$message({
            message: res.data.message,
            type: "error",
          });
        }

      });
    },

    //个人信息
    GetPersonalInfo() {
      PersonalInfo().then((res) => {
        this.User(res.data.data);
      });
    },

    //子传父(关闭奖品遮罩层)
    setShow() {
      this.$emit("setShow", false);
    },

    ...mapMutations(["User"]),
  },
  watch: {
    //播放声音并且调用Select_All方法
    WinningShow(v) {
      if (v == true) this.Select_All();
    },
  },
};
</script>

<style lang="scss" scoped>
#Show {
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .Show_box1 {
    width: 3.35rem;
    height: 1.86rem;
  }
}
</style>
